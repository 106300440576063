import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "preact/jsx-runtime";
import { useMemo } from "preact/hooks";
function LookupDropdownItem({ value, onAddressSelected, onContainerSelected, disabled, }) {
    const { Description, Id, Text, Type, } = value;
    const label = useMemo(() => {
        if (Type === "Container") {
            return (_jsxs(_Fragment, { children: [_jsx("div", { className: "item__title", children: Text }), _jsx("div", { className: "item__description", children: Description.replace("- ", "") })] }));
        }
        return _jsx("div", { className: "item__title", children: Text });
    }, [Type, Text, Description]);
    const clickEvent = (ev) => {
        ev.preventDefault();
        if (Type === "Container") {
            return onContainerSelected(Id);
        }
        return onAddressSelected(Id);
    };
    return (_jsx("button", { className: "dropdown__item", disabled: disabled, type: "button", onClick: clickEvent, children: label }));
}
LookupDropdownItem.defaultProps = {
    disabled: false,
};
export default LookupDropdownItem;
